export function bindFlipBoxes() {
  const flipBoxes = document.querySelectorAll(".flip-box");

  flipBoxes.forEach((flipBox) => {
    const flipForwardsTriggers = flipBox.querySelectorAll(".flip-forwards");

    flipForwardsTriggers.forEach((flipForwardsTrigger) => {
      flipForwardsTrigger.addEventListener("click", () => {
        flipForwards(flipBox);
      });
    });

    const flipBackwardsTriggers = flipBox.querySelectorAll(
      ".flip-backwards, .flip-box-back"
    );

    flipBackwardsTriggers.forEach((flipBackwardsTrigger) => {
      flipBackwardsTrigger.addEventListener("click", () => {
        flipBackwards(flipBox);
      });
    });
  });
}

function flipForwards(flipBox) {
  flipBox.classList.add("flipped");
}

function flipBackwards(flipBox) {
  flipBox.classList.remove("flipped");
}
