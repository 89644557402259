import React from "react";

interface Props {
  className: string;
}

export default function FacebookIcon({ className }: Props) {
  return (
    <svg
      className={`facebook-icon ${className}`}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path d="M31.3352,11.02579l-0.1386,4.7477c0,0-0.6065-0.0247-2.7355,0    c-2.0919,0.0248-2.1167,1.5844-2.1167,1.6587l-0.0247,3.9486l4.8769,0.0866l-0.7056,5.0503h-4.1466l-0.1114,10.645h-5.0255    l-0.1361-10.6203l-4.4066-0.0247l0.1238-5.1369h4.3323c0,0,0-3.7126,0-3.7134c0-1.4528,0.3473-3.0293,1.1752-4.2411    c0.7303-1.0689,1.8345-1.7742,3.0658-2.1352C25.3619,11.29109,27.9442,10.48079,31.3352,11.02579z" />
        </g>
      </g>
    </svg>
  );
}
