export function bindMenu() {
  const aside = document.getElementsByTagName("aside")[0];
  const html = document.getElementsByTagName("html")[0];
  const menuButton = document.querySelector("header .menu-button");
  const menuCloseButton = document.querySelector("aside .close");
  const menuItems = document.querySelectorAll("aside .menu-content nav a");
  const discloseMenuItems = document.querySelectorAll(
    "aside .menu-content nav a, aside .menu-content hr, aside .menu-content .social-media"
  );
  discloseMenuItems.forEach(function (item) {
    item.classList.add("undisclosed");
  });
  menuButton.addEventListener("click", function () {
    showMenu(aside, html, discloseMenuItems);
  });
  menuCloseButton.addEventListener("click", function () {
    hideMenu(html, aside, discloseMenuItems);
  });
  menuItems.forEach(function (menuItem) {
    menuItem.addEventListener("click", function () {
      hideMenu(html, aside, discloseMenuItems);
    });
  });
}

function hideMenu(html, aside, discloseMenuItems) {
  html.classList.remove("no-scroll");
  aside.classList.add("aside-invisible");
  aside.addEventListener("transitionend", function onTransitionend() {
    // remove class to remove transition via css
    aside.classList.remove("in-front");
    discloseMenuItems.forEach(function (item) {
      item.classList.add("undisclosed");
    });
    this.removeEventListener("transitionend", onTransitionend);
  });
}

function showMenu(aside, html, discloseMenuItems) {
  aside.classList.add("in-front");
  aside.classList.remove("aside-invisible");
  html.classList.add("no-scroll");
  setTimeout(function () {
    // Disclosure of Menu-Items
    const delay = 100;
    discloseMenuItems.forEach(function (item, index) {
      setTimeout(function () {
        item.classList.add("disclosing");
        item.classList.remove("undisclosed");
        item.addEventListener("transitionend", function onTransitionend() {
          // remove class to remove transition via css
          aside.classList.remove("disclosing");
          this.removeEventListener("transitionend", onTransitionend);
        });
      }, delay * index);
    });
  }, 300);
}
