import React from "react";

interface Props {
  className: string;
  size: "small" | "medium" | "large";
}

export default function CodeGewerkIcon({ className, size }: Props) {
  return (
    <svg
      className={`cg-logo ${size} ${className}`}
      viewBox="0 0 525 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="g10"
        transform="matrix(1.25, 0, 0, -1.25, -314.068115234375, 704.1000366210938)"
      >
        <g id="g12">
          <g id="g14" clipPath="url(#clipPath16)" />
          <g id="g20">
            <g id="g22" />
            <path
              id="path32"
              d="m 429.705,354.83 c -17.426,-17.425 -17.426,-45.675 0,-63.1 l 0,0 c 17.424,-17.425 45.674,-17.425 63.1,0 l 0,0 c 17.426,17.425 17.426,45.675 0,63.1 l 0,0 c -8.713,8.713 -20.132,13.069 -31.551,13.069 l 0,0 c -11.418,0 -22.837,-4.356 -31.549,-13.069"
            />
          </g>
        </g>
        <g id="g34">
          <g id="g36" clipPath="url(#clipPath38)" />
          <g id="g42">
            <g id="g44" />
            <path
              id="path54"
              d="m 283.255,380.194 c -31.303,-31.3 -31.303,-82.525 0,-113.828 l 0,0 121.086,-121.085 56.913,-56.914 56.914,56.914 95.965,95.964 -15.799,15.799 -87.821,-87.821 c -27.093,-27.093 -71.424,-27.093 -98.518,0 l 0,0 -43.909,43.91 -16.978,16.978 -16.977,16.977 -26.933,26.932 c -27.094,27.095 -27.094,71.425 0,98.519 l 0,0 87.82,87.82 -15.799,15.8 -95.964,-95.965 z"
            />
          </g>
        </g>
        <g id="g56">
          <g id="g58" clipPath="url(#clipPath60)" />
          <g id="g64">
            <g id="g66" />
            <path
              id="path76"
              d="m 404.341,501.28 -8.145,-8.145 15.799,-15.798 c 27.094,27.093 71.425,27.093 98.518,0 l 0,0 26.934,-26.933 15.798,15.799 -35.077,35.077 -56.914,56.913 -56.913,-56.913 z m 150.082,-67.854 -49.259,-49.258 16.977,-16.978 49.26,49.259 43.909,-43.91 c 27.095,-27.094 27.095,-71.424 0,-98.519 l 0,0 15.8,-15.798 8.144,8.144 c 31.303,31.303 31.303,82.528 0,113.828 l 0,0 -52.053,52.054 -16.978,16.978 -15.8,-15.8 z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

CodeGewerkIcon.defaultProps = {
  className: "",
  size: "small",
};
