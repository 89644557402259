import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import Header from "../components/header";
import HeroSection from "../components/HeroSection";
import ServiceSection from "../components/ServiceSection";
import ProjectSection from "../components/ProjectSection";
import TeamSection from "../components/TeamSection";
import PartnerSection from "../components/PartnerSection";
import Footer from "../components/Footer";

import SweetScroll from "sweet-scroll";
import { bindHeroSection } from "../client/hero-section-scroll-animation";
import { bindMenu } from "../client/show-hide-menu";
import { bindFlipBoxes } from "../client/flip-box";
import { bindUtilities } from "../client/utilities";
import {
  Company,
  PartnerSectionData,
  ProjectSectionData,
  ServiceSectionData,
  Service,
  TeamSectionData,
  TeamMember,
  Partner,
  Project,
} from "../types";

interface Props {
  data: {
    cockpitCompany: Company;
    cockpitServiceSection: ServiceSectionData;
    allCockpitServices: {
      nodes: [Service];
    };
    cockpitTeamSection: TeamSectionData;
    allCockpitTeammembers: {
      nodes: [TeamMember];
    };
    cockpitPartnerSection: PartnerSectionData;
    allCockpitPartners: {
      nodes: [Partner];
    };
    cockpitProjectSection: ProjectSectionData;
    allCockpitProjects: {
      nodes: [Project];
    };
  };
}

export default function Home({ data }: Props) {
  useEffect(() => {
    bindHeroSection();
    bindMenu();
    bindFlipBoxes();
    bindUtilities();

    document.addEventListener(
      "DOMContentLoaded",
      () => {
        new SweetScroll({ duration: 1700, easing: "easeInOutQuart" });
      },
      false
    );
  }, []);

  return (
    <Layout company={data.cockpitCompany}>
      <Navigation
        company={data.cockpitCompany}
        serviceSection={data.cockpitServiceSection}
        projectSection={data.cockpitProjectSection}
        teamSection={data.cockpitTeamSection}
        partnerSection={data.cockpitPartnerSection}
      />
      <Header company={data.cockpitCompany} />
      <HeroSection company={data.cockpitCompany} />
      <main>
        <ServiceSection
          sectionData={data.cockpitServiceSection}
          services={data.allCockpitServices.nodes}
        />
        <ProjectSection
          sectionData={data.cockpitProjectSection}
          projects={data.allCockpitProjects.nodes}
        />
        <TeamSection
          sectionData={data.cockpitTeamSection}
          members={data.allCockpitTeammembers.nodes}
        />
        <div className="bg-gradient-large-dark">
          <PartnerSection
            sectionData={data.cockpitPartnerSection}
            partners={data.allCockpitPartners.nodes}
          />
          <Footer company={data.cockpitCompany} />
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query {
    cockpitCompany {
      lawful {
        value
      }
      mail {
        value
      }
      name {
        value
      }
      slogan {
        value
      }
      description {
        value
      }
      facebook {
        value
      }
      instagram {
        value
      }
      hero_background_image {
        value {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    cockpitServiceSection {
      large_title {
        value
      }
      small_title {
        value
      }
      text {
        value
      }
      image {
        value {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }

    allCockpitServices(sort: { fields: index___value }) {
      nodes {
        image {
          value {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        title {
          value
        }
        details {
          value
        }
        description {
          value
        }
      }
    }

    cockpitTeamSection {
      large_title {
        value
      }
      small_title {
        value
      }
      text {
        value
      }
      image {
        value {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }

    allCockpitTeammembers(sort: { fields: index___value }) {
      nodes {
        activity {
          value
        }
        description {
          value
        }
        image {
          value {
            childImageSharp {
              fixed(width: 175, height: 175, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        name {
          value
        }
      }
    }

    cockpitPartnerSection {
      large_title {
        value
      }
      small_title {
        value
      }
    }

    allCockpitPartners(sort: { fields: index___value }) {
      nodes {
        url {
          value
        }
        image {
          value {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }

    cockpitProjectSection {
      large_title {
        value
      }
      small_title {
        value
      }
      text {
        value
      }
    }

    allCockpitProjects(sort: { fields: index___value }) {
      nodes {
        title {
          value
        }
        subtitle {
          value
        }
        description {
          value
        }
        url {
          value
        }
        image {
          value {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
