import React from "react";

import {
  SectionData,
  Company,
  ProjectSectionData,
  PartnerSectionData,
  ServiceSectionData,
  TeamSectionData,
} from "../types";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import CodeGewerkIcon from "./CodeGewerkIcon";

interface Props {
  company: Company;
  serviceSection: ServiceSectionData;
  projectSection: ProjectSectionData;
  teamSection: TeamSectionData;
  partnerSection: PartnerSectionData;
}

export default function Navbar({
  company,
  serviceSection,
  projectSection,
  teamSection,
  partnerSection,
}: Props) {
  const { facebook, instagram } = company;

  return (
    <aside className="aside-invisible">
      <div className="background" />
      <div className="brand">
        <CodeGewerkIcon size="medium" className="fill-white" />
      </div>
      <div className="menu-content">
        <div className="w-100">
          <nav className="text-white">
            <SectionLink target="services" sectionData={serviceSection} />
            <SectionLink target="projects" sectionData={projectSection} />
            <SectionLink target="team" sectionData={teamSection} />
            <SectionLink target="partner" sectionData={partnerSection} />
          </nav>
          <hr className="border-white small mt-0 mb-3" />
          <div className="social-media d-flex align-items-center justify-content-center">
            <a
              className="mr-2"
              rel="noreferrer"
              href={facebook.value}
              target="_blank"
            >
              <FacebookIcon className="small fill-white fill-cg-orange-hover transition-fill-ease-out" />
            </a>
            <a
              className="mr-2"
              rel="noreferrer"
              href={instagram.value}
              target="_blank"
            >
              <InstagramIcon className="small fill-white fill-cg-orange-hover transition-fill-ease-out" />
            </a>
          </div>
        </div>
      </div>
      <div className="close cursor-pointer">
        <svg
          className="close-icon small fill-white fill-cg-orange-hover transition-fill-ease-out"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " />
        </svg>
      </div>
    </aside>
  );
}

interface LinkProps {
  target: string;
  sectionData: SectionData;
}

function SectionLink({ target, sectionData }: LinkProps) {
  return (
    <a className="d-block my-4 text-cg-orange-hover" href={`#${target}`}>
      <h4 className="font-weight-light mb-0">
        {sectionData.small_title.value}
      </h4>
      <h2>{sectionData.large_title.value}</h2>
    </a>
  );
}
