import React from "react";

import { ServiceSectionData, Service } from "../types";
import CodeGewerkIcon from "./CodeGewerkIcon";

interface Props {
  sectionData: ServiceSectionData;
  services: [Service];
}

export default function ServiceSection({ sectionData, services }: Props) {
  const { text, small_title, large_title, image } = sectionData;

  return (
    <section id="services">
      <div className="container">
        <div className="overhanging-image-part text-lg-right">
          <div className="services-image overhanging-image right disclosure-element undisclosed disclosure-rank-lg-1 d-none d-lg-block">
            <img
              className="image"
              src={image.value.childImageSharp.original.src}
              alt=""
            />
          </div>
          <div className="section-headline white-dark-dimgray">
            <h2 className="disclosure-element undisclosed">
              {small_title.value}
            </h2>
            <h1 className="disclosure-element undisclosed">
              {large_title.value}
            </h1>
            <hr className="disclosure-element undisclosed" />
          </div>
          <p className="section-text text-dark-dimgray disclosure-element undisclosed">
            {text.value}
          </p>
        </div>
      </div>
      <div className="container">
        <div className="horizontal-scroll-container-xs horizontal-scroll-container-sm horizontal-scroll-container-md">
          <div className="row specific-services no-gutters flex-nowrap flex-lg-wrap">
            {services.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

interface CardProps {
  service: Service;
}

function ServiceCard({ service }: CardProps) {
  const { title, description, image, details } = service;

  const detailList = details.value
    .split("\n")
    .map((item) => item.trim())
    .filter((item) => item.length);

  return (
    <div className="col-auto col-lg-4 mt-5 mb-5 disclosure-element undisclosed disclosure-rank-md-0 disclosure-rank-lg-0 undisclosure-rank-md-1 undisclosure-rank-lg-2">
      <div className="flip-box service-item translate-up-hover transition-transform-ease-out">
        <div
          className="flip-box-front aspect-ratio-9-16 overlay-black-55 bg-size-cover bg-position-center box-shadow-dark-subtle box-shadow-dark-subtle-more-hover"
          style={{
            backgroundImage: `url("${image.value.childImageSharp.original.src}")`,
          }}
        >
          <div className="aspect-ratio-inner stick-out">
            <div className="p-4">
              <h2>{title.value}</h2>
              <hr className="small border-white" />
              <p>{description.value}</p>
              <div className="button button-primary flip-forwards">Details</div>
            </div>
            <div className="brand">
              <CodeGewerkIcon className="fill-white" />
            </div>
          </div>
        </div>
        <div className="flip-box-back box-shadow-dark-subtle box-shadow-dark-subtle-more-hover">
          <div className="stick-out h-100">
            <div className="p-3">
              <h3>{title.value}</h3>
              <ul className="pl-3">
                {detailList.map((detail, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: detail }}
                  ></li>
                ))}
              </ul>
              <a
                className="button button-primary ml-4 stop-propagation"
                href="mailto:info@codegewerk.de?subject=Anfrage Innovative Websites"
              >
                Anfragen
              </a>
            </div>
            <div className="brand">
              <CodeGewerkIcon className="fill-white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
