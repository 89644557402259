import React from "react";

import { Company } from "../types";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import CodeGewerkIcon from "./CodeGewerkIcon";

export interface Props {
  company: Company;
}

export default class Header extends React.PureComponent<Props> {
  componentDidMount() {
    document.addEventListener("DOMContentLoaded", handleVisibility);
    window.addEventListener("scroll", handleVisibility);
  }

  componentWillUnmount() {
    document.removeEventListener("DOMContentLoaded", handleVisibility);
    window.removeEventListener("scroll", handleVisibility);
  }

  render() {
    const { name, facebook, instagram } = this.props.company;

    return (
      <header className="header-invisible navbar fixed-top navbar-expand-sm bg-gradient-small-dark box-shadow-dark">
        <div className="container text-white">
          <a className="navbar-brand transition-all-ease-out" href="#">
            <div className="d-flex align-items-center">
              <CodeGewerkIcon className="fill-white mr-2" />
              <h5 className="mb-0 font-weight-light">{name.value}</h5>
            </div>
          </a>
          <div className="d-flex align-items-center py-2">
            <a
              className="transition-all-ease-out"
              rel="noreferrer"
              href={facebook.value}
              target="_blank"
            >
              <FacebookIcon className="small fill-white" />
            </a>
            <a
              className="ml-1 ml-md-3 transition-all-ease-out"
              rel="noreferrer"
              href={instagram.value}
              target="_blank"
            >
              <InstagramIcon className="small fill-white" />
            </a>
            <span className="separator mx-3 mx-md-4">|</span>
            <a className="menu-button">
              <div className="content-wrapper">
                <div className="bar-container">
                  <span className="bar-1" />
                  <span className="bar-2" />
                  <span className="bar-3" />
                </div>
                <div className="bar-container">
                  <span className="bar-1" />
                  <span className="bar-2" />
                  <span className="bar-3" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

function handleVisibility() {
  const header = document.getElementsByTagName("header")[0];
  const heroInner = document.getElementsByClassName("hero-section")[0];
  const heroSectionOffsetHeight = heroInner.offsetHeight;
  const scrollTop = window.scrollY;

  if (scrollTop > heroSectionOffsetHeight - 10) {
    if (header.classList.contains("header-invisible")) {
      header.classList.remove("header-invisible");
    }
  } else {
    if (!header.classList.contains("header-invisible")) {
      header.classList.add("header-invisible");
    }
  }
}
