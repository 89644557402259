function manageHeroSectionOpacity(heroInner, heroSection) {
  var heroSectionOffsetHeight = heroInner.offsetHeight;
  var scrollTop = window.scrollY;

  if (scrollTop < heroSectionOffsetHeight) {
    if (heroSection.style.visibility !== "visible") {
      heroSection.style.visibility = "visible";
    }

    //var halfScrollTop = scrollTop/2;
    var heroSectionDisappearedPercentage = scrollTop / heroSectionOffsetHeight;

    heroInner.style.opacity = 1 - 1.3 * heroSectionDisappearedPercentage;
  } else {
    if (heroSection.style.visibility !== "hidden") {
      heroSection.style.visibility = "hidden";
    }
  }
}

export function bindHeroSection() {
  var heroSection = document.getElementsByClassName("hero-section")[0];
  var heroInner = document.getElementsByClassName("hero-section")[0];
  heroInner.classList.add("disclose");

  manageHeroSectionOpacity(heroInner, heroSection);

  window.addEventListener("scroll", function () {
    manageHeroSectionOpacity(heroInner, heroSection);
  });
}
