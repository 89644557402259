export function bindUtilities() {
  const stopPropagationElements = document.querySelectorAll(
    ".stop-propagation"
  );

  stopPropagationElements.forEach((element) => {
    element.addEventListener("click", (event) => {
      event.stopPropagation();
    });
  });
}
