import React from "react";

interface Props {
  className: string;
}

export default function InstagramIcon({ className }: Props) {
  return (
    <svg
      className={`instagram-icon ${className}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M17,23H7a6,6,0,0,1-6-6V7A6,6,0,0,1,7,1H17a6,6,0,0,1,6,6V17A6,6,0,0,1,17,23ZM7,3A4,4,0,0,0,3,7V17a4,4,0,0,0,4,4H17a4,4,0,0,0,4-4V7a4,4,0,0,0-4-4Z" />
        <g data-name="<Group>">
          <path d="M12,18a6,6,0,1,1,6-6A6,6,0,0,1,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Z" />
        </g>
        <g data-name="<Group>">
          <circle cx={18} cy={5} r={1} />
        </g>
      </g>
    </svg>
  );
}
