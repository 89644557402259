import React from "react";
import Img from "gatsby-image";

import { Company } from "../types";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import CodeGewerkIcon from "./CodeGewerkIcon";
import CodeGewerkScripture from "./CodeGewerkScripture";

export interface Props {
  company: Company;
}

export default function HeroSection({ company }: Props) {
  const { slogan, name, facebook, instagram, hero_background_image } = company;

  return (
    <div className="hero-section">
      <div className="hero-inner h-100 bg-gradient-large-dark">
        <Img
          className="bg-size-cover bg-position-55-50 h-100 background-image-disclosure"
          loading="eager"
          fluid={hero_background_image.value.childImageSharp.fluid}
        />
        <div className="position-absolute left-0 top-0 w-100 h-100 d-flex align-items-center">
          <div className="container">
            <h1 className="text-white text-shadow-gray cg-scripture-page-disclosure">
              <div className="d-none d-md-block display-1 font-weight-normal">
                {name.value}
              </div>
              <div className="d-none d-sm-block d-md-none display-3 font-weight-normal">
                {name.value}
              </div>
              <div className="d-sm-none display-4 font-weight-normal">
                {name.value}
              </div>
            </h1>
            <div className="row pb-5">
              <div className="col-sm-7 col-lg-5">
                <h4
                  className="text-white text-shadow-gray font-weight-light headline-page-disclosure"
                  dangerouslySetInnerHTML={{ __html: slogan.value }}
                ></h4>
                <a
                  className="button button-primary button-large mt-4 rest-page-disclosure"
                  href="#services"
                  data-scroll
                >
                  Mehr Erfahren
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute top-0 rest-page-disclosure">
          <div className="p-3 p-md-4 p-lg-5">
            <CodeGewerkIcon size="medium" className="fill-white" />
          </div>
        </div>
        <div className="position-absolute top-0 right-0 rest-page-disclosure">
          <div className="d-flex align-items-center p-3 p-md-4 p-lg-5">
            <a
              className="mr-1 mr-lg-3"
              rel="noreferrer"
              href={facebook.value}
              target="_blank"
            >
              <FacebookIcon className="small fill-white fill-lightgray-hover transition-fill-ease-out" />
            </a>
            <a
              className="mr-1 mr-lg-3"
              rel="noreferrer"
              href={instagram.value}
              target="_blank"
            >
              <InstagramIcon className="small fill-white fill-lightgray-hover transition-fill-ease-out" />
            </a>
          </div>
        </div>
        <div className="position-absolute bottom-0 right-0 rest-page-disclosure">
          <div className="p-3 p-md-4 p-lg-5">
            <CodeGewerkScripture size="medium" color="white" />
          </div>
        </div>
      </div>
    </div>
  );
}
