import React from "react";
import { Link } from "gatsby";

import { Company } from "../types";
import erechtImgSrc from "../assets/images/erecht24-grau-agentur-klein.png";
import CodeGewerkScripture from "./CodeGewerkScripture";

export interface Props {
  company: Company;
}

export default function Footer({ company }: Props) {
  const { lawful, mail, name } = company;
  const year = new Date().getFullYear();

  return (
    <footer className="py-4 bg-black-55">
      <div className="container">
        <div className="row my-2 align-items-end justify-content-between">
          <div className="col-md-auto my-2">
            <div className="mb-2 mb-md-3" style={{ height: "2rem" }}>
              <CodeGewerkScripture color="white" className="h-100" />
            </div>
            <div className="text-white">
              <a className="text-silver" href={`mailto:${mail.value}`}>
                {mail.value}
              </a>
              <h6 className="mb-0">{lawful.value}</h6>
            </div>
          </div>
          <div className="col-md-auto my-2">
            <nav className="text-white">
              <a
                className="d-inline-block mr-3 mr-md-0 ml-md-4"
                href="#services"
              >
                <div className="text-silver font-weight-light ">unsere</div>
                <h6 className="mb-1 font-weight-normal">Leistungen</h6>
              </a>
              <a
                className="d-inline-block mr-3 mr-md-0 ml-md-4"
                href="#projects"
              >
                <div className="text-silver font-weight-light ">aktuelle</div>
                <h6 className="mb-1 font-weight-normal">Projekte</h6>
              </a>
              <a className="d-inline-block mr-3 mr-md-0 ml-md-4" href="#team">
                <div className="text-silver font-weight-light ">unser</div>
                <h6 className="mb-1 font-weight-normal">Team</h6>
              </a>
              <a
                className="d-inline-block mr-3 mr-md-0 ml-md-4"
                href="#partner"
              >
                <div className="text-silver font-weight-light ">unsere</div>
                <h6 className="mb-1 font-weight-normal">Partner</h6>
              </a>
            </nav>
          </div>
          <div className="col-md-auto my-2 text-md-right">
            <div style={{ height: "60px" }}>
              <img
                className="filter-grayscale-90 h-100"
                src={erechtImgSrc}
                alt={"eRecth 24"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-2">
        <div className="border-bottom border-white w-100" />
      </div>
      <div className="container my-2 d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <Link
            className="mr-2 mr-md-3 text-white text-gray-hover d-inline-block"
            to="/legal/impressum"
          >
            <h6 className="font-weight-light mb-0">Impressum</h6>
          </Link>
          <Link
            className="mr-2 mr-md-3 text-white text-gray-hover d-inline-block"
            to="/legal/privacy-policy"
          >
            <h6 className="font-weight-light mb-0">Datenschutz</h6>
          </Link>
        </div>
        <div>
          <h6 className="mb-0 text-white font-weight-light">
            © {year}
            <a className="ml-1 font-weight-bold text-gray-hover" href="#">
              {name.value}
            </a>
          </h6>
        </div>
      </div>
    </footer>
  );
}
