import React from "react";

import { PartnerSectionData, Partner } from "../types";

interface Props {
  sectionData: PartnerSectionData;
  partners: [Partner];
}

export default function PartnerSection({ sectionData, partners }: Props) {
  const { small_title, large_title } = sectionData;

  return (
    <section id="partner">
      <div className="container mb-5">
        <div className="section-headline cg-orange-white">
          <h2 className="disclosure-element undisclosed">
            {small_title.value}
          </h2>
          <h1 className="disclosure-element undisclosed">
            {large_title.value}
          </h1>
          <hr className="disclosure-element undisclosed" />
        </div>
      </div>
      <div className="container">
        <div className="horizontal-scroll-container-xs horizontal-scroll-container-sm horizontal-scroll-container-md">
          <div className="row flex-nowrap flex-lg-wrap">
            {partners.map((partner, index) => (
              <PartnerCard key={index} partner={partner} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

interface CardProps {
  partner: Partner;
}

function PartnerCard({ partner }: CardProps) {
  const { url, image } = partner;

  return (
    <div className="col-auto col-lg-4">
      <div className="my-5 my-lg-4 my-xl-5">
        <div className="partner-item border-rounded box-shadow-dark-subtle filter-grayscale-90 filter-initial-hover transition-all-ease-out translate-up-hover box-shadow-dark-subtle-more-hover">
          <a href={url?.value} rel="noreferrer" target="_blank">
            <div className="px-5 py-6">
              <div
                className="bg-size-contain bg-position-center w-100"
                style={{
                  height: "70px",
                  backgroundImage: `url("${image.value.childImageSharp.original.src}")`,
                }}
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
