import React from "react";
import Img from "gatsby-image";

import { TeamMember, TeamSectionData } from "../types";

interface Props {
  sectionData: TeamSectionData;
  members: [TeamMember];
}

export default function TeamSection({ sectionData, members }: Props) {
  const { image, small_title, large_title, text } = sectionData;

  return (
    <section id="team">
      <div className="container mb-5">
        <div className="overhanging-image-part">
          <div className="team-image overhanging-image left disclosure-element undisclosed disclosure-rank-lg-1">
            <img
              className="image"
              alt=""
              src={image.value.childImageSharp.original.src}
            />
          </div>
          <div className="section-headline white-dark-dimgray">
            <h2 className="disclosure-element undisclosed">
              {small_title.value}
            </h2>
            <h1 className="disclosure-element undisclosed">
              {large_title.value}
            </h1>
            <hr className="disclosure-element undisclosed" />
          </div>
          <p
            className="section-text text-dark-dimgray disclosure-element undisclosed"
            dangerouslySetInnerHTML={{
              __html: `${members.length - 1} ${text.value}`,
            }}
          ></p>
        </div>
      </div>
      <div className="container">
        <div className="horizontal-scroll-container-xs horizontal-scroll-container-sm horizontal-scroll-container-md">
          <div className="team-members-container row no-gutters flex-nowrap flex-lg-wrap">
            {members.map((member, index) => (
              <TeamMemberCard key={index} member={member} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

interface CardProps {
  member: TeamMember;
}

function TeamMemberCard({ member }: CardProps) {
  const { name, activity, description, image } = member;

  return (
    <div className="col-auto col-lg-6 mt-5 mb-3 disclosure-element undisclosed disclosure-rank-sm-1 undisclosure-rank-md-0">
      <div className="team-member row">
        <div className="col-8 col-sm-12 col-lg-5">
          <Img
            className="image filter-grayscale-90 filter-initial-hover transition-all-ease-out translate-up-hover box-shadow-dark-subtle-more-hover"
            fixed={image.value.childImageSharp.fixed}
            alt=""
          />
        </div>
        <div className="col-lg-7">
          <div className="mt-4">
            <h5 className="text-white font-weight-light mb-1">
              {activity.value}
            </h5>
            <h3 className="text-dark-dimgray mb-3">{name.value}</h3>
            <p
              className="text-dimgray font-weight-normal"
              dangerouslySetInnerHTML={{ __html: description.value }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
}
