import React from "react";
import Img from "gatsby-image";

import { ProjectSectionData, Project } from "../types";
import CodeGewerkScripture from "./CodeGewerkScripture";

interface Props {
  sectionData: ProjectSectionData;
  projects: [Project];
}

export default function ProjectSection({ sectionData, projects }: Props) {
  const { small_title, large_title, text } = sectionData;

  return (
    <section id="projects">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-6">
            <div className="section-headline cg-orange-white">
              <h2 className="disclosure-element undisclosed">
                {small_title.value}
              </h2>
              <h1 className="disclosure-element undisclosed">
                {large_title.value}
              </h1>
              <hr className="disclosure-element undisclosed" />
            </div>
            <p className="section-text text-white disclosure-element undisclosed">
              {text.value}
            </p>
          </div>
        </div>
        <div className="horizontal-scroll-container-xs horizontal-scroll-container-sm horizontal-scroll-container-md">
          <div className="specific-projects row no-gutters flex-nowrap flex-lg-wrap">
            {projects.map((project, index) => (
              <ProjectCard key={index} project={project} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

interface CardProps {
  project: Project;
}

function ProjectCard({ project }: CardProps) {
  const { title, subtitle, description, url, image } = project;

  return (
    <div className="col-auto col-lg-6">
      <div className="mt-5 mb-5">
        <div className="flip-box project-item translate-up-hover transition-transform-ease-out disclosure-element undisclosed">
          <div className="flip-box-front box-shadow-dark-subtle box-shadow-dark-subtle-more-hover bg-gradient-small-dark">
            <div className="stick-out">
              <div className="project-image">
                <a href={url?.value} rel="noreferrer" target="_blank">
                  <Img fluid={image.value.childImageSharp.fluid} alt="" />
                </a>
              </div>
              <div className="p-3 p-lg-4">
                <div className="project-key-data">
                  <h3 className="text-white">
                    <a
                      className="text-darkgray-hover"
                      rel="noreferrer"
                      href={url?.value}
                      target="_blank"
                    >
                      {title.value}
                    </a>
                  </h3>
                  <h5 className="text-silver font-weight-light">
                    {subtitle.value}
                  </h5>
                </div>
                <div className="mt-4 mb-2">
                  <a className="button button-primary transition-all-ease-out flip-forwards">
                    Mehr Erfahren
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flip-box-back box-shadow-dark-subtle box-shadow-dark-subtle-more-hover bg-white">
            <div className="stick-out p-3 p-lg-4 p-xl-5 text-dark-dimgray h-100">
              <div>
                <h3>{title.value}</h3>
                <p>{description.value}</p>
                {url && (
                  <a
                    className="stop-propagation button button-dark"
                    href={url.value}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Zum Projekt
                  </a>
                )}
              </div>
              <div className="text-right position-absolute bottom-0 right-0 m-3 m-lg-4 m-xl-5">
                <CodeGewerkScripture size="medium" color="dark-dimgray" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
